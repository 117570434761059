import { FreightType } from "../../types/common/common";

/**
 * 주어진 화물 유형에 따라 기본 공급 값을 반환하는 함수
 *
 * @param freightType - 화물 유형 (FreightType) 또는 undefined
 * @returns 주어진 화물 유형에 해당하는 기본 공급 값 또는 "-" (화물 유형이 undefined인 경우)
 */
const getBaseSupply = (freightType: FreightType | undefined) =>
  freightType
    ? {
        FCL: "20DRY",
        LCL: "1R.TON",
        AIR: "100C.W",
      }[freightType]
    : "-";

export { getBaseSupply };
